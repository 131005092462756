import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const ATireDAile = () => (
  <Layout>
    <SEO title="À Tire d'aile" description="À Tire d'aile, une création pour l'espace urbain autour du souvenir." />
    <section id="a-tire-d-aile-banner">
        <div>
            <h1 style={{color: `#000`}}>À TIRE D'AILE - 2020</h1>
            <p>
                <strong>
                    Durée 45 minutes. <br />
                    Pièce pour 3 interprètes. <br />
                    Pour l'espace public.
                </strong>
            </p>
        </div>
    </section>

    <section className="main container">
        <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/les-creations/">Les créations</Link></li>
            <li className="breadcrumb-item active" aria-current="page">À Tire d'aile</li>
        </ol>
        </nav>

        <div className="citations">
            <p>« Ce corps que tu vois dans la rue,</p>
            <p>Le visage creusé par le temps, il n’a pas perdu la brillance,</p>
            <p>La malice de son regard.</p>
            <p>C’est un enfant qui a grandi malgré lui.</p>
            <p>Un enfant qui a accumulé dans sa besace,</p>
            <p>souvenirs, sensations, frustrations,</p>
            <p>Rêves et émotions.</p>
            <p>Il est le fantôme de sa propre existence. Sa peau constitue son autobiographie la plus sincère.</p>
            <p>Il lui arrive encore de se perdre dans de longues pensées,</p>
            <p>de revivre toutes ses premières fois.</p>
            <p>Si ce corps ne peut pas voyager dans le temps, il lui sera toujours possible de s’imaginer une brèche dans le présent.</p>
            <p>S’évader le temps d’une danse. »</p>
        </div>
    </section>
    <svg xmlns="http://www.w3.org/2000/svg" style={{width: `100%`, margin: `0`, padding: `0`}} width="1921.046" height="136.58" viewBox="0 0 1921.046 136.58">
        <path id="Tracé_10" data-name="Tracé 10" d="M7960,1363c915.685-43.829,1394.665-76.561,1645.546-98.86C9881.02,1239.654,9880,1227.819,9880,1227.819" transform="translate(-7959.953 -1227.416)" fill="none" stroke="#2d2c2b" strokeWidth="2"/>
    </svg>
    <section className="main container">
        <h2>Présentation</h2>
        <p>A tire d’aile est une création autour du souvenir, d’anecdote de vie de l’enfance jusqu’à aujourd’hui. Réalisé à partir de conversations, d’entretien auprès de passants dans la rue, et de récits intimes, ce projet prend racine dans la vie quotidienne.</p>
        <p className="citations">« Je m’interroge sur le temps qui passent car j’ai toujours l’impression qu’il m’échappe. »</p>
        <p>Il me tient à cœur au travers de cette création de sublimer l’invisible, l’éphémère, ces détails du quotidien qui nous rendent vivant, et nous remplissent de sensations. Je souhaite travailler avec l’intime, lui donner une dimension dans laquelle chaque spectateur peut se sentir touché.</p>
        <p>Je m’inspire du contraste entre l’enfance, cette période où notre potentiel pouvait éclater vers milles directions et la complexité du monde des adultes.</p>
        <p>La chorégraphie oscille entre fulgurance et délicatesse, fragilité et résistance. Les corps entrent dans une course contre la montre, et tente de trouver une place.</p>
        <div className="row align-items-center" style={{marginBottom: `2rem`}}>
            <div className="col-md-6 col-sm-12 mb-3">
                <Image filename="a-tire-d-aile-affiche.jpg" alt="Affiche de la pièce" title="Affiche de la pièce" />
            </div>
            <div className="col-md-6 col-sm-12 mb-3">
                    <Image filename="a-tire-d-aile-photo-2.jpg" className="mb-5" alt="Photo prise lors de la résidence aux Studio Antipodes à Nice, été 2020." title="Résidence été 2020 aux Studio Antipodes à Nice" />
                    <Image filename="a-tire-d-aile-photo-1.jpg" alt="Photo prise lors de la résidence aux Studio Antipodes à Nice, été 2020." title="Résidence été 2020 aux Studio Antipodes à Nice" />
            </div>
        </div>
    </section>
    <svg xmlns="http://www.w3.org/2000/svg" style={{width: `100%`, margin: `0`, padding: `0`}} width="1921.047" height="139.391" viewBox="0 0 1921.047 139.391">
      <path id="Tracé_11" data-name="Tracé 11" d="M7960,1227.819c915.685,44.744,1394.665,78.159,1645.546,100.923,275.473,25,274.453,37.077,274.453,37.077" transform="translate(-7959.952 -1226.821)" fill="none" stroke="#2d2c2b" strokeWidth="2"/>
    </svg>
    <section className="main container">
        <h2>Nos partenaires</h2>
        <div className="row align-items-center justify-content-center" style={{marginBottom: `2rem`}}>
            <div className="col-md-1 mb-3"></div>
            <div className="col-md-2 mb-3">
                <a href="http://le109.nice.fr/" target="_blank" rel="noreferrer">
                    <Image filename="logo-le109-2017.png" className="le-109 partner-logo" alt="Le 109 : Pôle de culture contemporaines à Nice" title="Le 109 : Pôle de culture contemporaines à Nice" /> 
                </a>
            </div>
            <div className="col-md-2 mb-3">
                <a href="https://www.studio-antipodes.com/" target="_blank" rel="noreferrer">
                    <Image filename="logo-studio-antipodes.png" className="partner-logo" alt="Studio Antipodes, Danse pour l'espace public à Nice" title="Studio Antipodes, Danse pour l'espace public à Nice" />
                </a>
            </div>
            <div className="col-md-2 mb-3">
                    <Image filename="logo-Centre-Amantica.png" className="partner-logo" rel="noreferrer" alt="Centre Amantica, St-Orens" title="Centre Amantica, St-Orens" />
            </div>
            <div className="col-md-2 mb-3">
                <a href="https://www.helloasso.com/associations/compagnie-ailes-de-cire" target="_blank" rel="noreferrer">
                    <Image filename="logo-hello-asso.png" className="helloasso partner-logo" alt="Helloasso et l'ensenble des donnateurs." title="Helloasso" />
                </a>
            </div>
            <div className="col-md-2 mb-3">
                <a href="https://www.larochelle.fr/" target="_blank" rel="noreferrer">
                    <Image filename="logo-ville-la-rochelle.png" className="la-rochelle"  alt="Ville de La Rochelle" title="Ville de La Rochelle" />
                </a>
            </div>
            <div className="col-md-1 mb-3"></div>
        </div>
        
    </section>
  </Layout>
)

export default ATireDAile
